import groupPayoutsModule from '../groupPayoutsModule';

function configureGroupPayoutsController($window) {
  'ngInject';

  const ctrl = this;

  const init = function () {
    ctrl.payoutRedirectLinkStartHtml = `<a href="https://create.roblox.com/dashboard/group/payouts?groupId=${ctrl.groupId}" class="text-link">`;
  };

  const payoutBannerLocalStorageKey = 'rbx.PayoutFeaturesBanner';

  ctrl.clickedPayoutFeaturesBanner = false;

  ctrl.isPayoutFeaturesBannerDisplayed = function () {
    if (ctrl.clickedPayoutFeaturesBanner) {
      return false;
    }

    if (!$window.localStorage) {
      return true;
    }

    return $window.localStorage.getItem(payoutBannerLocalStorageKey) !== 'true';
  };

  ctrl.closePayoutFeaturesBanner = function () {
    ctrl.clickedPayoutFeaturesBanner = true;

    if (!$window.localStorage) {
      return;
    }

    $window.localStorage.setItem(payoutBannerLocalStorageKey, 'true');
  };

  ctrl.$onInit = init;
}

groupPayoutsModule.controller('configureGroupPayoutsController', configureGroupPayoutsController);
export default configureGroupPayoutsController;
